import { config, library } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {
  faGoogle,
  faLinkedin,
  faHackerrank,
  faMicrosoft,
  faTwitter,
  faWindows,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import {
  faAdjust,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowsAltH,
  faBan,
  faBell,
  faBold,
  faBookOpen,
  faBoxOpen,
  faC,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarPlus,
  faCalendarTimes,
  faCalendarWeek,
  faChartLine,
  faChartPie,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faClipboard,
  faClipboardCheck,
  faClock,
  faCode,
  faCog,
  faCogs,
  faConciergeBell,
  faDatabase,
  faDoorClosed,
  faDoorOpen,
  faDownload,
  faEnvelopeOpenText,
  faEraser,
  faExclamation,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileCsv,
  faFileDownload,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileUpload,
  faFileVideo,
  faFileWord,
  faHammer,
  faHistory,
  faHourglassHalf,
  faIdBadge,
  faInfoCircle,
  faItalic,
  faKey,
  faLayerGroup,
  faLegal,
  faListOl,
  faListUl,
  faLock,
  faLongArrowRight,
  faMapMarked,
  faMinusCircle,
  faPaintRoller,
  faPaperclip,
  faPaperPlane,
  faPassport,
  faPhone,
  faPhoneSquareAlt,
  faPlus,
  faPlusCircle,
  faProjectDiagram,
  faQuestionCircle,
  faQuoteRight,
  faRedo,
  faRobot,
  faSearch,
  faShareAlt,
  faShoePrints,
  faStopwatch,
  faSync,
  faSyncAlt,
  faTable,
  faTimesCircle,
  faTools,
  faTrashAlt,
  faTrashRestoreAlt,
  faUnderline,
  faUpload,
  faUser,
  faBoltLightning,
  faUserCircle,
  faUserClock,
  faUserEdit,
  faUserFriends,
  faUserPlus,
  faUsers,
  faUsersCog,
  faUserSecret,
  faUserTie,
  faVideo,
  faUserMinus
} from "@fortawesome/free-solid-svg-icons";

import {
  faCheckCircle as faCheckCircleDuo,
  faCircleNotch,
  faClock as faClockDuo,
  faQuestionCircle as faQuestionCircleDuo,
  faRepeat as faRepeatDuo,
  faTimesCircle as faTimesCircleDuo,
  faUserCircle as faUserCircleDuo,
  faWindowMaximize as faWindowMaximizeDuo,
  faWindowMinimize as faWindowMinimizeDuo
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faDownload as faDownloadLight,
  faExclamationCircle as faExclamationCircleLight,
  faTimes as faTimesLight,
  faUpload as faUploadLight,
  faUser as faUserLight,
  faBoltLightning as faBoltLightningLight,
  faOctagon as faOctagonLight
} from "@fortawesome/pro-light-svg-icons";
import {
  faBuilding as faBuildingRegular,
  faExclamationCircle,
  faSquare as faSquareRegular
} from "@fortawesome/pro-regular-svg-icons";
import {
  faAlarmClock,
  faAlarmPlus,
  faAnalytics,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleUp,
  faAngleDown,
  faAngleUp,
  faArchive,
  faArrowAltRight,
  faArrowsAltV,
  faArrowsH,
  faBellExclamation,
  faBellOn,
  faBellPlus,
  faBells,
  faBellSchool,
  faBellSchoolSlash,
  faBellSlash,
  faBooks,
  faBriefcase,
  faBuilding,
  faBusinessTime,
  faCalendarCircleUser,
  faCalendarEdit,
  faCalendarExclamation,
  faCalendarMinus,
  faCalendarStar,
  faChalkboardTeacher,
  faChartNetwork,
  faChevronCircleDown,
  faChevronDoubleDown,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDoubleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faClipboardList,
  faClipboardListCheck,
  faClipboardUser,
  faClone,
  faCloud,
  faCloudDownload,
  faCloudRainbow,
  faClouds,
  faCloudUpload,
  faColumns,
  faComment,
  faCommentAlt,
  faCommentAltCheck,
  faCommentAltEdit,
  faCommentAltLines,
  faCommentAltSlash,
  faCommentExclamation,
  faCommentLines,
  faComments,
  faCommentSlash,
  faCompressAlt,
  faCopy,
  faEdit,
  faEnvelope,
  faExchange,
  faExclamationTriangle,
  faExpandAlt,
  faExternalLink,
  faEye,
  faFileCertificate,
  faFileExport,
  faFileInvoice,
  faFilePlus,
  faFileSignature,
  faFileSpreadsheet,
  faFileUser,
  faFlag,
  faFlaskPotion,
  faGlobe,
  faGlobeStand,
  faGripLinesVertical,
  faH1,
  faH2,
  faH3,
  faH4,
  faHorizontalRule,
  faImagePolaroid,
  faLanguage,
  faLayerPlus,
  faLightbulbOn,
  faLineColumns,
  faLineHeight,
  faLink,
  faLocationCircle,
  faLockAlt,
  faLockOpenAlt,
  faMailBulk,
  faMapMarkerAlt,
  faMapMarkerEdit,
  faMapMarkerMinus,
  faMapMarkerPlus,
  faMerge,
  faMessageBot,
  faMinus,
  faMinusSquare,
  faMoneyCheckEdit,
  faOctagon,
  faPencil,
  faPersonCarry,
  faPhoneOffice,
  faPlay,
  faPollPeople,
  faPresentation,
  faRandom,
  faRetweet,
  faSave,
  faScrubber,
  faServer,
  faShare,
  faShareAll,
  faShareAltSquare,
  faShoppingCart,
  faShovel,
  faSignInAlt,
  faSignOut,
  faSignOutAlt,
  faSitemap,
  faSlash,
  faSlidersVSquare,
  faSms,
  faSpinner,
  faSpinnerThird,
  faSquare,
  faStickyNote,
  faSuitcase,
  faSun,
  faSunDust,
  faSunrise,
  faSunset,
  faTachometerAltFast,
  faTasks,
  faTasksAlt,
  faTelescope,
  faTextWidth,
  faTh,
  faTimes,
  faTrophy,
  faTrophyAlt,
  faUmbrellaBeach,
  faUndo,
  faUndoAlt,
  faUnlink,
  faUnlockAlt,
  faUserCog,
  faUserCrown,
  faUserHardHat,
  faUserLock,
  faUsersClass,
  faUserShield,
  faUsersMedical,
  faUserTag,
  faVial,
  faWandMagic,
  faWarning,
  faWebcam,
  faWebcamSlash,
  faWindowClose,
  faWindowMaximize
} from "@fortawesome/pro-solid-svg-icons";

export const registerIcons = () => {
  config.autoAddCss = true;
  library.add(
    faBookOpen,
    faCircle,
    faBooks,
    faUsers,
    faRobot,
    faUser,
    faBoltLightning,
    faUserCircle,
    faUserClock,
    faTachometerAltFast,
    faCog,
    faCogs,
    faEnvelopeOpenText,
    faCircle,
    faScrubber,
    faAdjust,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDoubleDown,
    faCheckCircle,
    faBusinessTime,
    faH1,
    faH2,
    faH3,
    faH4,
    faQuoteRight,
    faListUl,
    faListOl,
    faCode,
    faUnderline,
    faItalic,
    faBold,
    faTextWidth,
    faSignInAlt,
    faUserShield,
    faUserSecret,
    faKey,
    faUserHardHat,
    faTools,
    faPaintRoller,
    faHammer,
    faShovel,
    faGoogle,
    faMicrosoft,
    faWindows,
    faWhatsapp,
    faUserTie,
    faMailBulk,
    faCalendar,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarDay,
    faCalendarEdit,
    faCalendarExclamation,
    faCalendarMinus,
    faCalendarPlus,
    faCalendarStar,
    faCalendarTimes,
    faCalendarWeek,
    faGlobe,
    faEnvelope,
    faUserPlus,
    faLock,
    faLockAlt,
    faUnlockAlt,
    faLockOpenAlt,
    faUsersCog,
    faRedo,
    faClock,
    faLineHeight,
    faFlaskPotion,
    faFile,
    faFileImage,
    faFileAudio,
    faFileVideo,
    faFileAlt,
    faFileCode,
    faFileCsv,
    faFilePdf,
    faFileWord,
    faFilePowerpoint,
    faFileExcel,
    faFileArchive,
    faFileSpreadsheet,
    faArchive,
    faSignOut,
    faSignOutAlt,
    faWindowClose,
    faPlay,
    faTasksAlt,
    faSpinner,
    faLink,
    faUnlink,
    faTimesCircle,
    faSunset,
    faSunrise,
    faUserFriends,
    faPollPeople,
    faPersonCarry,
    faBuilding,
    faUserTag,
    faWebcam,
    faWebcamSlash,
    faVideo,
    faUserMinus,
    faArrowRightArrowLeft,
    faC,
    faLegal,
    faMoneyCheckEdit,
    faStopwatch,
    faInfoCircle,
    faPhoneSquareAlt,
    faLocationCircle,
    faGlobe,
    faDoorOpen,
    faDoorClosed,
    faPhoneOffice,
    faAngleDoubleDown,
    faAngleDoubleUp,
    faAngleDoubleLeft,
    faTh,
    faClipboardUser,
    faFileUser,
    faSlidersVSquare,
    faCloudDownload,
    faTable,
    faTasks,
    faDatabase,
    faRetweet,
    faProjectDiagram,
    faChartNetwork,
    faConciergeBell,
    faCloudUpload,
    faFileUpload,
    faFileDownload,
    faClipboardList,
    faClipboardListCheck,
    faImagePolaroid,
    faPassport,
    faShare,
    faShareAlt,
    faShareAltSquare,
    faUserCog,
    faClouds,
    faFileCertificate,
    faBan,
    faEyeSlash,
    faClone,
    faTrashAlt,
    faTrashRestoreAlt,
    faPaperPlane,
    faShareAll,
    faGlobeStand,
    faPhone,
    faMapMarked,
    faSpinnerThird,
    faExclamationTriangle,
    faAlarmPlus,
    faAlarmClock,
    faTimes,
    faPlusCircle,
    faMinusCircle,
    faUserEdit,
    faHistory,
    faEraser,
    faPlus,
    faPencil,
    faExclamation,
    faQuestionCircle,
    faExternalLink,
    faUsersMedical,
    faSearch,
    faLightbulbOn,
    faWindowMaximize,
    faMessageBot,
    faColumns,
    faLineColumns,
    faArrowsH,
    faArrowsAltH,
    faArrowAltRight,
    faArrowRight,
    faAnalytics,
    faChartLine,
    faTrophy,
    faTrophyAlt,
    faUserLock,
    faChartPie,
    faClipboardCheck,
    faClipboard,
    faPresentation,
    faMapMarkerAlt,
    faMapMarkerPlus,
    faMapMarkerMinus,
    faMapMarkerEdit,
    faExchange,
    faEdit,
    faSync,
    faSyncAlt,
    faUndo,
    faUndoAlt,
    faVial,
    faEye,
    faCopy,
    faSitemap,
    faAngleDown,
    faAngleUp,
    faBriefcase,
    faSms,
    faShoppingCart,
    faSlash,
    faFlag,
    faServer,
    faCloud,
    faFilePlus,
    faPaperclip,
    faWandMagic,
    faDownload,
    faUpload,
    faUserCrown,
    faCommentAltLines,
    faBoxOpen,
    faSquare,
    faCheckSquare,
    faFileInvoice,
    faUsersClass,
    faFileExport,
    faFileSignature,
    faCommentAltEdit,
    faCommentAltCheck,
    faRandom,
    faTwitter,
    faChalkboardTeacher,
    faCheckDouble,
    faIdBadge,
    faArrowsAltV,
    faTelescope,
    faStopwatch,
    faGripLinesVertical,
    faComment,
    faComments,
    faCommentLines,
    faLanguage,
    faExpandAlt,
    faCompressAlt,
    faCommentSlash,
    faCommentExclamation,
    faHorizontalRule,
    faMinus,
    faChevronLeft,
    faChevronRight,
    faSun,
    faSunDust,
    faBell,
    faBellOn,
    faBellSlash,
    faBellExclamation,
    faBellPlus,
    faBells,
    faShoePrints,
    faChevronDoubleUp,
    faChevronDoubleDown,
    faEdit,
    faRobot,
    faCommentAlt,
    faCommentAltSlash,
    faChevronCircleDown,
    faShoePrints,
    faSuitcase,
    faLayerGroup,
    faLayerPlus,
    faBellSchool,
    faBellSchoolSlash,
    faHourglassHalf,
    faUmbrellaBeach,
    faChevronDown,
    faChevronUp,
    faMinusSquare,
    faWarning,
    faCircleInfo,
    faCloudRainbow,
    faLinkedin,
    faHackerrank,
    faStickyNote,
    faMerge,
    faArrowRight,
    faLongArrowRight,
    faSave
  );

  // regular pro icons
  library.add({
    faBuildingRegular,
    faExclamationCircle,
    faCalendarCircleUser,
    faSquareRegular
  });

  // light icons
  library.add({
    faTimesLight,
    faUserLight,
    faBoltLightningLight,
    faDownloadLight,
    faUploadLight,
    faExclamationCircleLight,
    faOctagonLight
  });

  // duotone icons
  library.add({
    faUserCircle,
    faCircleNotch,
    faQuestionCircleDuo,
    faTimesCircleDuo,
    faClockDuo,
    faUserCircleDuo,
    faCheckCircleDuo,
    faWindowMaximizeDuo,
    faWindowMinimizeDuo,
    faRepeatDuo
  });
};
