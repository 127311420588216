import "../wdyr.ts";

/* eslint-disable import/no-cycle */
import * as Sentry from "@sentry/react";
import "bootstrap/dist/css/bootstrap.css";
import "core-js/features/array/at";
import "core-js/features/promise/any";
import moment from "moment/min/moment-with-locales";
import React, { Suspense } from "react";
import { ClientContext, GraphQLClient } from "graphql-hooks";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink
} from "@apollo/client";
import { Provider } from "react-redux";
import { LicenseInfo } from "@mui/x-license-pro";
import Firebase, { FirebaseContext } from "./components/Firebase";
import { WorkersCacheProvider } from "./contexts/cache/workers";
import "./i18n/i18n";
import "./index.css";
import { unregister as unregisterServiceWorker } from "./serviceWorkerRegistration";
import configureStore from "./store";
import "./styles/styles.scss";
import App from "./components/App";
import { getServerUrl } from "./constants"; //  This always need to be imported last

window.global ||= window;

if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(async (registrations) => {
    await Promise.all(
      registrations.map((registration) => registration.unregister())
    );
    // removing reload for now as it's constantly refreshing the page
    // if (registrations.length > 0) {
    //   window.location.reload();
    // }
  });
}

// temporarily disabling service worker until refresh issues are resolved
//if (window.location.hostname === "localhost") {
unregisterServiceWorker();
//} else {
//  registerServiceWorker();
//}

export const store = configureStore();
// export const persistor = persistStore(store);
export const firebase = new Firebase();
export const { db, storage } = firebase;

// Create a client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const getGraphqlAuthLink = (token) => {
  return new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: token
      }
    }));
    return forward(operation);
  });
};

export const graphqlClient = new GraphQLClient({
  url: `${getServerUrl()}/graphql`,
  onError: ({ result }) => {
    // update firebase token in graphql client on unauthorized error
    if (
      result?.error?.graphQLErrors?.some(
        (err) => err?.message === "Unauthorized"
      )
    ) {
      firebase.auth?.currentUser
        ?.getIdToken()
        .then((token) => {
          graphqlClient.setHeader("Authorization", `Bearer ${token}`);
        })
        .catch(() => {});
    }
  }
});

const httpLink = createHttpLink({
  uri: `${import.meta.env.REACT_APP_URL_API}/graphql`
});

const apolloClient = new ApolloClient({
  link: [httpLink],
  cache: new InMemoryCache()
});

// listen to token change event and update graphql client header
try {
  firebase.auth?.onIdTokenChanged((user) => {
    if (user) {
      user
        .getIdToken()
        .then((token) => {
          graphqlClient.setHeader("Authorization", `Bearer ${token}`);
          const authLink = getGraphqlAuthLink(`Bearer ${token}`);
          apolloClient.setLink(authLink.concat(httpLink));
        })
        .catch(() => {
          graphqlClient.removeHeader("Authorization");
        });
    }
  });
} catch (e) {
  console.log(e);
}

LicenseInfo.setLicenseKey(import.meta.env.REACT_APP_MUI_PRO_LICENSE_KEY);

// const cacheProvider = {
//   get: (language, key) => {
//     // TODO: pull from language bundle first, then database second
//     return ((JSON.parse(localStorage?.getItem("translations")) || {})[key] ||
//       {})[language];
//   },
//   set: (language, key, value) => {
//     // TODO: save to database
//     const existing = JSON.parse(localStorage?.getItem("translations")) || {
//       [key]: {}
//     };
//     existing[key] = { ...existing[key], [language]: value };
//     try {
//       localStorage.setItem("translations", JSON.stringify(existing));
//     } catch (error) {
//       console.error(error);
//     }
//   }
// };

moment.locale(localStorage?.getItem("language") ?? "en");

const WrappedApp = () => (
  <Sentry.ErrorBoundary fallback="An Error has occurred, please refresh your page.">
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <FirebaseContext.Provider value={firebase}>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={apolloClient}>
            <ClientContext.Provider value={graphqlClient}>
              <WorkersCacheProvider>
                <Suspense fallback={null}>
                  <App />
                </Suspense>
              </WorkersCacheProvider>
            </ClientContext.Provider>
          </ApolloProvider>
        </QueryClientProvider>
      </FirebaseContext.Provider>
      {/* </PersistGate> */}
    </Provider>
  </Sentry.ErrorBoundary>
);

ReactDOM.render(<WrappedApp />, document.getElementById("root"));
